/* http://paletton.com/#uid=3450u0k3i7H4mcB3L9O4O7c9y6a

main blue: #0D0747
dark almost-black: #1A1922

light gray: #EEEEF3
med gray: 333338
dark gray: 1A1922

light brown: D38100  
med brown:  673F00
dark brown: 412802

dark red: 411300

*/

$primary: #36334f;
$secondary: #412802;
$text: #302B29;
$bgcolor: #f7f7f5;
$gray: #888888;


body {
    color: $text;    
    background-color: $bgcolor;
    font-family: "Montserrat", serif;
    margin: 0px;
    line-height: 1.3;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
}

.container {
    width: 100%;
    max-width: 100%;
    padding: 0px;
}

code {
    white-space: pre-wrap;
}

.page {
    padding: 25px 15px 25px 15px;
    max-width: 1400px;
    margin: auto;
}

.post {
    padding: 0px 25px 25px 25px;
    margin: 15px;
}


/* ------------------------
   LINKS
   ------------------------
*/

a:link {color:$secondary;}      /* unvisited link */
a:visited {color:#673F00;}  /* visited link */
a:hover {color:#462A03;}  /* mouse over link */
a:active {color:#462A03;}  /* selected link */

/* ------------------------
   BLOG TITLE
   ------------------------
*/

.titlebox {
    text-align: left;
    padding: 10px;
    background-image: url('/assets/images/quill.jpg');
    background-size: cover;
    background-position: right center 50%;
    
    background-blend-mode: darken;
    
}

.titleimage {
    background-image: url('/assets/images/logo4.jpg');
    background-size: cover;
    background-position: left 70%;
    height: 250px;
    
}

.blogtitle {
    /* 400 - weight normal 1.3 = line height */
    /*font: 400 50px/1.3 'Great Vibes', Helvetica, sans-serif; */
    color: $primary;
    font: 30px 'Helvetica', sans-serif;
    padding: 5px;
    /*text-shadow: 1px 1px 0px #ededed, 4px 4px 0px rgba(0,0,0,0.15);    */
}

.blogtitle a, .blogtitle a:visited, .blogtitle a:hover, .blogtitle a:link {
    text-decoration: none;
    color: $primary;   
    /* font: 700 50px/1.3 'Great Vibes', Helvetica, sans-serif; */
    font-family: 'Great Vibes', cursive;
    font-size: 50px;
}

.tagline {
    padding: 0px 5px 10px 5px;
    color: $secondary;
    font-size: 22px;
}

.blog-preview { 
    margin: 20px;
    padding: 10px;
    width: 300px;
    height: 425px;
    float: left;
}

.blog-preview ul li {
    list-style-type: none;
    display: inline;
    float: left;
    padding: 10px;
    text-align: center;
}

.blog-preview img {
    max-width: 300px;
    height: 200px;
    display:block;
    margin: auto;
}
.social-bar-header { 
    float: right;
    text-align: center; 
}

.social-bar-contact { 
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.social-bar-contact > a.img {
  height: 50px;
}

.social-bar-header img {
    height: 25px;
}

.social-bar-contact img {
    height: 50px;
}

.alli-icon {
  height: 125px;
}

.home-blurb {
  margin: auto;
  text-align: center;
  max-width: 600px;
  font-size: 1.25em;
  line-height: 1.5;
}
/* ------------------------
   SIDEBAR
   ------------------------
*/


.box-title {
    font-weight: bold;
    margin: 10px;
}

.sidebar {
    margin: 15px;
    background: $bgcolor;   
     border-radius: 10px; 
     border: 1px dotted $primary;
}

.sidebar-box {
    padding: 2px;
    /* border-bottom: 1px solid $primary; */
}

.sidebar ul {
    padding: 0px;
    padding-left: 10px;
    
}
.sidebar li {
    list-style-type: none;
    line-height: 1.5em;
}

.sidebar li a {
    text-decoration: none;
}

.tags-box {
    margin: 15px;
}

.tags-box a {
    padding: 3px;
    text-decoration: none; 
}

/* ------------------------
   NAVBAR
   ------------------------
*/

.navbar {
    display: flex;
    justify-content: center; 
   /* border-top: 1px solid $primary;  */
   padding: 5px;
    background: $primary;
    border-top: 2px solid $primary;
    border-bottom: 2px solid $primary;
}

.nav li {
    list-style-type: none;
    display: inline;
    padding: 10px;
    font-family: 'Great Vibes', cursive;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 24px;
}

.nav a, .nav a:visited, .nav a:hover, .nav a:link {
    text-decoration: none;
    color: #ece9e5;
}




/* ------------------------
   LINES
   ------------------------
*/

hr { 
    color: $primary;
    border-color: $primary;
    background-color: $primary;
}

.short-divider {
  width: 30%;
  margin: 25px auto 25px auto;
  border-color: $secondary;
  background-color: $secondary;
}

/* ------------------------
   HEADERS
   ------------------------
*/

.title-words {
    background-color: $primary;
    font-size: 28px;
    padding: 2px 30px 10px 30px;   
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.title {
    text-align: center;
    margin-bottom: 20px;   
}

.title a 
{ 
    text-decoration: none; 
    color:$primary;
}

.title a:visited {color:#fff;}    
.title a:hover {color:#fff;}      
.title a:active {color:#fff;}   
.title a:link {color:#fff;}      /* unvisited link */

h1, h2, h3 {
    color: $primary;
}

h2 {
  margin-bottom: 20px;
  
}

/* ------------------------
   WORK GALLERY
   ------------------------
*/

.gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}

.gallery-item {
  padding: 25px 0px 25px 0px;
  /* border-top: 1px dotted #ccc; */
  width: 400px;
}

.gallery-details {
  padding-left: 20px;
  text-align: center;
}

.gallery-desc h2 {
  font-size: 1.25em;
}

.gallery-title {
  font-weight: bold;
  font-size: 1.5em;
  padding-bottom: 10px;
  color: $primary;
  margin-top: 20px;
}

.gallery-title a {
  text-decoration: none;
  color: $primary;
}

.gallery-image {
  flex-basis: 300px; 
  max-width: 300px;
  display: block;
  margin: auto;
}

.gallery-image img {
    max-width: 100%;
    margin: 0 auto 0 auto;
    display: block;
}

.gallery-desc {
  max-width: 60%;
  margin: auto;
}



.rpg-gallery {
}

.rpg-gallery-item {
  border-top: 1px solid $secondary;
  padding: 20px;
}

.rpg-gallery-desc h2 {
  font-size: 1.25em;
}

.rpg-gallery-title {
  font-weight: bold;
  font-size: 1.5em;
  padding-bottom: 10px;
  color: $primary;
  margin-top: 20px;
}

.rpg-gallery-title a {
  text-decoration: none;
  color: $primary;
}

.rpg-gallery-heading {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;  
}

.rpg-gallery-image {
  max-width: 600px;
  padding: 0px 20px 20px 0px;
}

.rpg-gallery-image img {
    max-width: 100%;
    margin: 0 auto 0 auto;
    display: block;
}


/* ------------------------
   MISC
   ------------------------
*/

.book-subtitle {
  font-size: 1.5em;
}

.hats {
    color: $secondary;   
    font-weight: bold;
    
}

.collage {
    text-align: center;
}

.collage img {
    width: 600px;
    max-width: 100%;
}

.date {
    padding: 10px;
}

.tag {
    padding: 10px;
}

.comment {
    padding: 10px;
}

.share-box {
    padding: 10px;
}

.book {
    border-top: 1px dotted $primary; 
    padding: 15px 10px 10px 10px;
}

.book-desc {
}

.profile {
    border-top: 1px solid $primary;
    margin: 0px 20px 0px 0px;
}

blockquote {
      margin-left: 0px;
      padding: 15px;
      border-left: 4px solid $primary;
      border-top: 1px dotted $primary;
      border-bottom: 1px dotted $primary;
      background: #f3f2ee;
    } 

.brand {
    font-size: 30px;
    background-color: black;
}

.page-header {
    margin: auto;
    text-align: center;
}


img.prettyImage-image {
	max-width: 600px;
	max-height: 600px;
	border: 2px solid $primary;

	margin-top:10px;
	margin-bottom:10px;
    display: block;
       margin-left: auto;
       margin-right: auto;
}

.footer {
    font-size: 12px;
    color: $secondary;
    padding: 5px;
    border-top: 1px solid $primary;
}

.data-box {
    padding: 15px;  
    border: 1px solid $primary;
    margin: 10px;    
}

.affiliate {
  font-style: italic;
  text-align: center;
  color: $gray;
  margin-top: 30px;
}

.spacer {
  padding: 25px;
}

.spacer-sm {
  padding: 15px;
}

.accordion-button:not(.collapsed) {
  background-color: $primary;
  color: white;
}

.amff-note {
  font-size: .9em;
  line-height: 10px;
}

.award-badge {
  max-width: 275px;
}

.award-badge-sm {
  max-width: 75px;
  padding-right: 20px;
}

#accordionReviews .review-heading {
  flex-direction: column;
  display: flex;
}
/* ------------------------
   PAGINATION
   ------------------------
*/

.pagination {
    margin: 10px;
}

.pagination ul {
    list-style-type: none;
    -webkit-margin-before: 1px;
    -webkit-margin-after: 1px;
    -webkit-margin-start: 1px;
    -webkit-margin-end: 1px;
    -webkit-padding-start: 1px;
}

.pagination li {
    float:left;
    padding-left: 25%;
}


.story-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.story-details {
  padding-left: 10px;
  max-width: 1000px;
}

.feature-image {
    width: 400px;
    max-width: 100%;
    padding-right: 20px;
    
}

.feature-image img {
    max-width: 400px;
    max-width: 100%;
    padding: 10px;
}

.button {
    background-color: $primary;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    margin: 5px;
}

.button-secondary {
  background-color: $gray;
}

a.button {
  color: white;
  text-decoration: none;
  line-height: 1em;
}

.visit-blog {
  padding-top: 25px;
}

.cover-render {
  margin: 20px auto 20px auto;
}

.cover-render img {
  max-width: 80%;
  width: 600px;
  display: block;
  margin: auto;
}

.profile-block {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image-wrap {
  display: block;
  margin: auto;
  width: 80%;
  max-width: 1000px;  
  margin-bottom: 25px;
}

.image-wrap img {
  max-width: 100%;
}

.book-blurb {
  padding: 0px 50px 0px 50px;
}

.book-tagline {
  font-weight: bold;
  font-size: 1.25em;
}

.buy-now {
  margin: 20px 0px 20px 0px;
}

#inline_toc {
    background-color: $bgcolor;
    padding: 5px;
    border-bottom: 1px solid $primary;
    border-top: 1px solid $primary;
    margin: 20px 50px 20px 50px;
}

.review-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.review {
  width: 400px;
}

.review img {
  max-width: 400px;
}

.accordion {
  margin: 15px 0px 15px 0px;
}

.accordion-body h2 {
  font-size: 1em;
  font-weight: bold;
}

.book-trailer iframe {
  display: block;
  margin: auto;
}

.link-tree {
  background-color: $bgcolor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  
  .profile-image {
    padding: 0px;
    margin: 0px;
    img {
      border-radius: 100px;
    }
  }
  
  a {
    text-decoration: none;    
  }
  
 

}

.link-tree-title {
  
  background-color: $primary;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  
  h2 {
    font-size: 1.5em;
    color: white;
  }
  
  h3 {
    font-size: 1.25em;
    color: white;
  }
  
  
  
}
.link-tree-item {
  width: 700px;
  max-width: 95%;
  border: 1px solid $primary;
  border-radius: 10px;
  padding: 15px;
  margin: 20px;
  
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
   
  img {
    height: 50px;
    border-radius: 50px;
  }
  
  .link-text {
    text-align: center;
    width: 100%;
    font-size: 1.5em;
  }
  
}

.link-tree-item:hover {
    background-color: $primary;
    
    a {
      color: white;
    }
  }


/*  GO FULL WIDTH BELOW 480 PIXELS */
@media only screen and (max-width: 1100px) {

  .container {
    width: 100%;
  }
  
  .blogtitle a, .blogtitle a:visited, .blogtitle a:hover, .blogtitle a:link {
    font-size: 30px;
  }
  
  .tagline {
    font-size: 20px;
  }

  
  .gallery-item {
    flex-direction: column;
  }
  
  .gallery-details {
    padding: 10px 0px 10px 0px;
    flex: auto;
  }
  
  .gallery-item {
    width: 100%;
    max-width: 100%;
  }

 .gallery-details {
  width: 100%;
  max-width: 100%;
 }

  .gallery-image {
    flex: auto;
    display: block;
    margin: auto;
  }
  
  .gallery-title, .gallery-desc h2 {
    text-align: center;
  }
  
  .gallery-desc > a.button {
    display: block;
    max-width: 200px;
    margin: auto;
  }
  
  .feature-image {
    padding: 0px;
  }
  
  .book-blurb {
    padding: 20px;
  }
  
}